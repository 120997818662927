import React from "react"

const TopLeft = () => {
  return (
    <svg width={20} height={20} className="corner-svg top-left">
      <path
        d="M 0 20 L0 0 L20 0"
        fill="none"
        stroke="black"
        strokeWidth="5px"
      />
    </svg>
  )
}

const TopRight = () => {
  return (
    <svg width={20} height={20} className="corner-svg top-right">
      <path
        d="M0 0 L20 0 L20 20"
        fill="none"
        stroke="black"
        strokeWidth="5px"
      />
    </svg>
  )
}

const BottomLeft = () => {
  return (
    <svg width={20} height={20} className="corner-svg bottom-left">
      <path
        d="M0 0 L0 20 L20 20"
        fill="none"
        stroke="black"
        strokeWidth="5px"
      />
    </svg>
  )
}

const BottomRight = () => {
  return (
    <svg width={20} height={20} className="corner-svg bottom-right">
      <path
        d="M0 20 L20 20 L20 0"
        fill="none"
        stroke="black"
        strokeWidth="5px"
      />
    </svg>
  )
}

export {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight
}